var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "loading" },
      [
        _vm.loading
          ? _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" },
            })
          : _vm._e(),
      ],
      1
    ),
    !_vm.loading
      ? _c(
          "div",
          [
            _c("v-text-field", {
              staticClass: "pb-5 cms-search",
              attrs: {
                "hide-details": "",
                outlined: "",
                "background-color": "white",
                solo: "",
                flat: "",
                autocomplete: "off",
                "append-icon": "fas fa-search fa-flip-horizontal",
                label: "Search",
                type: "text",
              },
              on: {
                input: _vm.searchTermChanged,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchClicked.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _vm.searchTerm
                          ? _c("v-icon", { on: { click: _vm.clearInput } }, [
                              _vm._v("mdi-close"),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2415087772
              ),
              model: {
                value: _vm.searchTerm,
                callback: function ($$v) {
                  _vm.searchTerm = $$v
                },
                expression: "searchTerm",
              },
            }),
            !_vm.activeSearchResults && _vm.isLoggedIn
              ? _c("i", [
                  _vm._v(
                    'No files found for "' + _vm._s(this.$route.query.q) + '".'
                  ),
                ])
              : !_vm.activeSearchResults && !_vm.isLoggedIn
              ? _c("i", [
                  _vm._v(
                    'No files found for "' +
                      _vm._s(this.$route.query.q) +
                      '", try logging in for more results'
                  ),
                ])
              : _vm._e(),
            _vm.activeSearchResults
              ? _c(
                  "v-list",
                  { attrs: { "two-line": "" } },
                  [
                    _c(
                      "v-list-item-group",
                      { attrs: { color: "primary" } },
                      [
                        _vm._l(_vm.activeSearchResults, function (result, i) {
                          return _c("Result", {
                            key: i,
                            staticClass: "search-result",
                            attrs: { result: result },
                          })
                        }),
                        _vm.fileName
                          ? _c("p", [
                              _vm._v("Folder Name: " + _vm._s(_vm.fileName)),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "Total Files: " +
                              _vm._s(_vm.activeSearchResults.length)
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }