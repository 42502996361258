var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { clipped: _vm.$vuetify.breakpoint.lgAndUp, app: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "title" }, [
                    _vm._v(" Aileron Search "),
                  ]),
                  _c("v-list-item-subtitle", [_vm._v(" Settings ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          attrs: {
            "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
            app: "",
            color: "primary",
            dark: "",
            flat: "",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c(
            "v-toolbar-title",
            { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
            [
              _c("span", { staticClass: "hidden-sm-and-down" }, [
                _vm._v("Aileron Search"),
              ]),
            ]
          ),
          _c("v-text-field", {
            attrs: {
              flat: "",
              "solo-inverted": "",
              "hide-details": "",
              "prepend-inner-icon": "mdi-magnify",
              label: "Search",
            },
            on: { input: _vm.searchTermChanged },
            model: {
              value: _vm.searchTerms,
              callback: function ($$v) {
                _vm.searchTerms = $$v
              },
              expression: "searchTerms",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-5",
              attrs: { disabled: _vm.isSearching === true, color: "secondary" },
              on: { click: _vm.searchAileron },
            },
            [_vm._v("Search")]
          ),
        ],
        1
      ),
      _c(
        "v-content",
        [
          _vm.searchResults != null
            ? _c(
                "v-container",
                { staticClass: "fill-height", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "start", justify: "start" } },
                    [
                      _c("v-col", [
                        _vm._v(
                          _vm._s(_vm.searchResults.hits.total.value) +
                            " results (" +
                            _vm._s(_vm.searchResults.took / 1000) +
                            " seconds)"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.resultHeaders,
                          items: _vm.searchResults.hits.hits,
                          "items-per-page": 5,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }