var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "", color: "menu", "overlay-opacity": "75" },
      model: {
        value: _vm.drawerState,
        callback: function ($$v) {
          _vm.drawerState = $$v
        },
        expression: "drawerState",
      },
    },
    _vm._l(_vm.myApplications, function (application, i) {
      return _c(
        "v-list",
        {
          key: i,
          staticClass: "pt-3",
          attrs: { subheader: "", dense: "", nav: "", dark: "" },
        },
        [
          _c(
            "v-subheader",
            [
              _c("v-icon", { staticClass: "pr-3", attrs: { small: "" } }, [
                _vm._v(_vm._s(application.icon)),
              ]),
              _vm._v(" " + _vm._s(application.text) + " "),
            ],
            1
          ),
          _vm._l(application.links, function (link, i) {
            return _c(
              "v-list-item",
              {
                key: i,
                staticClass: "pl-5",
                attrs: {
                  link: "",
                  to: link.external === false ? link.to : "",
                  href: link.external === true ? link.to : "",
                },
              },
              [
                _c(
                  "v-list-item-icon",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-3", attrs: { small: "" } },
                      [_vm._v(_vm._s(link.icon))]
                    ),
                  ],
                  1
                ),
                _c("v-list-item-title", {
                  domProps: { textContent: _vm._s(link.text) },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }