import { render, staticRenderFns } from "./DocumentResults.vue?vue&type=template&id=c98bdf24&scoped=true"
import script from "./DocumentResults.vue?vue&type=script&lang=ts"
export * from "./DocumentResults.vue?vue&type=script&lang=ts"
import style0 from "./DocumentResults.vue?vue&type=style&index=0&id=c98bdf24&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c98bdf24",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src400484917/src/search.myaileron.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c98bdf24')) {
      api.createRecord('c98bdf24', component.options)
    } else {
      api.reload('c98bdf24', component.options)
    }
    module.hot.accept("./DocumentResults.vue?vue&type=template&id=c98bdf24&scoped=true", function () {
      api.rerender('c98bdf24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/search/DocumentResults.vue"
export default component.exports