var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", { attrs: { fluid: "", "grid-list-xl": "" } }, [
    _c(
      "div",
      { staticClass: "loading" },
      [
        _vm.loading
          ? _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" },
            })
          : _vm._e(),
      ],
      1
    ),
    !_vm.loading
      ? _c(
          "div",
          [
            _c("v-text-field", {
              staticClass: "pb-5 cms-search",
              attrs: {
                "hide-details": "",
                outlined: "",
                autocomplete: "off",
                "append-icon": "fas fa-search fa-flip-horizontal",
                label: "Search",
                type: "text",
                flat: "",
                solo: "",
                "background-color": "white",
              },
              on: {
                input: _vm.searchTermChanged,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchClicked.apply(null, arguments)
                },
              },
              model: {
                value: _vm.searchTerm,
                callback: function ($$v) {
                  _vm.searchTerm = $$v
                },
                expression: "searchTerm",
              },
            }),
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              _vm._l(_vm.activeFeaturedFolders, function (item, index) {
                return _c(
                  "v-sheet",
                  { key: index, staticClass: "featured-card" },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.featuredItemClick(item)
                          },
                        },
                      },
                      [_c("FeaturedItem", { attrs: { item: item } })],
                      1
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }