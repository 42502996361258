var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column folders-tree-card",
      attrs: { flat: "", tile: "" },
    },
    [
      _vm.editFolder === null
        ? _c("div", [
            _c("p", { staticClass: "ma-5", attrs: { centered: "" } }, [
              _vm._v("No folder selected"),
            ]),
          ])
        : _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    dark: "",
                    grow: "",
                    "background-color": "secondary",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", { attrs: { href: "#folderPropertiesTab" } }, [
                    _vm._v("Properties"),
                  ]),
                  _c("v-tab", { attrs: { href: "#folderAccessTab" } }, [
                    _vm._v("Access"),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "folderPropertiesTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          !_vm.editFolder.imageUrl
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "pb-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "featured-folder-icon",
                                          attrs: { color: "primary" },
                                        },
                                        [_vm._v("mdi-folder")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editFolder.imageUrl
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "pb-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-img", {
                                        staticClass: "logo_class",
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          src: _vm.editFolder.imageUrl,
                                          height: "215px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.showImageUrl
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.imageOptions,
                                          "item-text": "fileName",
                                          "item-value": "fileUrl",
                                          label: "Featured Image",
                                          filled: "",
                                          hint: "Image to display on the featured folder tile",
                                          "persistent-hint": "",
                                          dense: "",
                                          disabled:
                                            _vm.disableInput ||
                                            !_vm.selectedFolder.userAccess
                                              .canUpdate,
                                        },
                                        model: {
                                          value: _vm.editFolder.imageUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editFolder,
                                              "imageUrl",
                                              $$v
                                            )
                                          },
                                          expression: "editFolder.imageUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showImageUrl
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          flat: "",
                                          "solo-inverted": "",
                                          "persistent-hint": "",
                                          hint: "Image to display on the featured folder tile",
                                          label: "Image Url",
                                          disabled:
                                            _vm.disableInput ||
                                            !_vm.selectedFolder.userAccess
                                              .canUpdate,
                                        },
                                        model: {
                                          value: _vm.editFolder.imageUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editFolder,
                                              "imageUrl",
                                              $$v
                                            )
                                          },
                                          expression: "editFolder.imageUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { small: "", depressed: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showImageUrl = !_vm.showImageUrl
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showImageUrl
                                            ? "Select CMS image"
                                            : "Use external image url"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Display title of the folder",
                                      label: "Title",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess
                                          .canUpdate,
                                    },
                                    model: {
                                      value: _vm.editFolder.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editFolder, "title", $$v)
                                      },
                                      expression: "editFolder.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0 pb-3",
                                    attrs: {
                                      label: "Featured",
                                      color: "primary",
                                      hint: "Sets the folder show as a featured folder",
                                      "persistent-hint": "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess
                                          .canUpdate,
                                    },
                                    model: {
                                      value: _vm.editFolder.featured,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "featured",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.featured",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Description of the folder",
                                      label: "Description",
                                      rows: "1",
                                      "auto-grow": "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess
                                          .canUpdate,
                                    },
                                    model: {
                                      value: _vm.editFolder.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.disciplines,
                                      label: "Disciplines",
                                      multiple: "",
                                      chips: "",
                                      filled: "",
                                      hint: "Default disciplines to be added when adding new documents",
                                      "persistent-hint": "",
                                      dense: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess
                                          .canUpdate,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled,
                                                  },
                                                  on: {
                                                    "click:close": function (
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: { left: "" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    ),
                                                  },
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.editFolder.disciplines,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "disciplines",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.disciplines",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      label: "Tags",
                                      dense: "",
                                      multiple: "",
                                      flat: "",
                                      filled: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess
                                          .canUpdate,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "no-data",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        ' No results matching " '
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(_vm.searchTag)
                                                        ),
                                                      ]),
                                                      _vm._v('". Press '),
                                                      _c("kbd", [
                                                        _vm._v("enter"),
                                                      ]),
                                                      _vm._v(
                                                        " to create a new one "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "selection",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled,
                                                  },
                                                  on: {
                                                    "click:close": function (
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: {
                                                    left: "",
                                                    small: "",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    ),
                                                  },
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.editFolder.tags,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editFolder, "tags", $$v)
                                      },
                                      expression: "editFolder.tags",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.selectedFolder.userAccess.canUpdate
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            color: "primary",
                                            disabled: _vm.disableInput,
                                            loading: _vm.disableInput,
                                          },
                                          on: { click: _vm.saveProperties },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "pr-2" },
                                            [_vm._v("fas fa-save")]
                                          ),
                                          _vm._v("Save "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" },
                                },
                                [
                                  _vm._v(" Updated by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedFolder.modifiedBy ||
                                          "User not found"
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedFolder.modifiedOn
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" },
                                },
                                [
                                  _vm._v(" Created by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedFolder.createdBy ||
                                          "User not found"
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedFolder.createdOn
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "folderActivityTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-timeline",
                            [
                              _c(
                                "v-timeline-item",
                                {
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "opposite",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "headline font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    _vm.editFolder.modifiedOn
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "headline font-weight-light mb-4",
                                      },
                                      [_vm._v("Folder Modified")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "by " +
                                          _vm._s(_vm.editFolder.modifiedBy)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-timeline-item",
                                {
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "opposite",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "headline font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    _vm.editFolder.createdOn
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "headline font-weight-light mb-4",
                                      },
                                      [_vm._v("Folder Created")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "by " + _vm._s(_vm.editFolder.createdBy)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "folderAccessTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-switch", {
                            staticClass: "mx-2",
                            attrs: {
                              label: "Is Public",
                              hint: "Sets the document to be viewable to all users",
                              color: "primary",
                              "persistent-hint": "",
                              loading: _vm.disableInput,
                              disabled:
                                !_vm.selectedFolder.userAccess
                                  .canManagePermissions,
                            },
                            on: { change: _vm.saveProperties },
                            model: {
                              value: _vm.editFolder.isPublic,
                              callback: function ($$v) {
                                _vm.$set(_vm.editFolder, "isPublic", $$v)
                              },
                              expression: "editFolder.isPublic",
                            },
                          }),
                          _c(
                            "div",
                            [
                              _vm.selectedFolder.userAccess.canManagePermissions
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        color: "primary",
                                        depressed: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showNewPerimssion = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-plus")]),
                                      _vm._v("Add Permission "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-form",
                                {
                                  ref: "permissionForm",
                                  model: {
                                    value: _vm.newPermissionValid,
                                    callback: function ($$v) {
                                      _vm.newPermissionValid = $$v
                                    },
                                    expression: "newPermissionValid",
                                  },
                                },
                                [
                                  _vm.showNewPerimssion
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: ["Group", "Email"],
                                                  hint: "Access Type",
                                                  flat: "",
                                                  dense: "",
                                                  "solo-inverted": "",
                                                  "persistent-hint": "",
                                                  disabled: _vm.disableInput,
                                                },
                                                model: {
                                                  value: _vm.newPermissionType,
                                                  callback: function ($$v) {
                                                    _vm.newPermissionType = $$v
                                                  },
                                                  expression:
                                                    "newPermissionType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.newPermissionType === "Group"
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.permissionGroups,
                                                      "item-text": "name",
                                                      "item-value": "name",
                                                      hint: "Type to select a group",
                                                      flat: "",
                                                      dense: "",
                                                      "solo-inverted": "",
                                                      "persistent-hint": "",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Group is required",
                                                      ],
                                                      disabled:
                                                        _vm.disableInput,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newPermissionGroup,
                                                      callback: function ($$v) {
                                                        _vm.newPermissionGroup =
                                                          $$v
                                                      },
                                                      expression:
                                                        "newPermissionGroup",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.newPermissionType === "Email"
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      hint: "Enter an Aileron account email",
                                                      flat: "",
                                                      "solo-inverted": "",
                                                      "persistent-hint": "",
                                                      dense: "",
                                                      rules: _vm.emailRule,
                                                      disabled:
                                                        _vm.disableInput,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newPermissionEmail,
                                                      callback: function ($$v) {
                                                        _vm.newPermissionEmail =
                                                          $$v
                                                      },
                                                      expression:
                                                        "newPermissionEmail",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    depressed: "",
                                                    disabled: _vm.disableInput,
                                                    loading: _vm.disableInput,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.saveNewPermission,
                                                  },
                                                },
                                                [_vm._v("Save")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    depressed: "",
                                                    disabled: _vm.disableInput,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showNewPerimssion = false
                                                      _vm.newPermissionGroup =
                                                        ""
                                                      _vm.newPermissionEmail =
                                                        ""
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("h3", { staticClass: "mt-2" }, [
                                _vm._v("Groups"),
                              ]),
                              _c("v-data-table", {
                                staticClass: "elevation-0",
                                attrs: {
                                  "calculate-widths": "",
                                  headers: _vm.permissionHeaders,
                                  "sort-by": ["createdOn"],
                                  "sort-desc": [true],
                                  items: _vm.groupPermissions,
                                  "items-per-page": 100,
                                  "item-key": "name",
                                  "hide-default-footer": "",
                                  "fixed-header": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.id",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              icon: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openRemovePermission(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("h3", [_vm._v("Users")]),
                              _c("v-data-table", {
                                staticClass: "elevation-0",
                                attrs: {
                                  "calculate-widths": "",
                                  headers: _vm.permissionHeaders,
                                  "sort-by": ["createdOn"],
                                  "sort-desc": [true],
                                  items: _vm.userPermissions,
                                  "items-per-page": 100,
                                  "item-key": "name",
                                  "hide-default-footer": "",
                                  "fixed-header": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.id",
                                    fn: function ({ item }) {
                                      return [
                                        _vm.selectedFolder.userAccess
                                          .canManagePermissions
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openRemovePermission(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "500" },
                              model: {
                                value: _vm.showRemovePermission,
                                callback: function ($$v) {
                                  _vm.showRemovePermission = $$v
                                },
                                expression: "showRemovePermission",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-overlay",
                                    {
                                      attrs: {
                                        absolute: "",
                                        value: _vm.disableInput,
                                      },
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      }),
                                      _c(
                                        "h2",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "10px",
                                            "verticle-align": "middle",
                                          },
                                        },
                                        [_vm._v("Removing permission")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [_vm._v("Remove permission")]
                                  ),
                                  _c("v-container", [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "Are you sure you want to remove permission for " +
                                          _vm._s(
                                            _vm.selectedPermission.roleName
                                          ) +
                                          "?"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary " },
                                          on: { click: _vm.removePermission },
                                        },
                                        [_vm._v("Yes")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showRemovePermission = false
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }