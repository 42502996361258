var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            clipped: "",
            "expand-on-hover": "",
            "mini-variant-width": "56",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "fill-height", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    column: "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "", nav: "" } },
                    [
                      _vm._l(_vm.applications, function (link) {
                        return _c(
                          "v-list-item",
                          { key: link.text, attrs: { link: "" } },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v(_vm._s(link.icon))])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(link.text)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-list-item",
                        { attrs: { link: "", "justify-end": "" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-settings-outline")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Settings")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          staticClass: "primary",
          attrs: { app: "", flat: "", "clipped-left": "" },
        },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { color: "white" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("v-toolbar-title", { attrs: { "font-color": "white" } }, [
            _vm._v("My Portal"),
          ]),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: { left: "", bottom: "", "offset-y": "", "offset-x": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "white", size: "48" } },
                            [
                              _c("span", { attrs: { headline: "" } }, [
                                _vm._v("DL"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.editProfile } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-account-edit-outline"),
                          ]),
                          _vm._v(" Edit Profile "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.signOut } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-logout-variant"),
                          ]),
                          _vm._v(" Sign Out "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-content",
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }