var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "myaileron" } },
    [
      _c("Toolbar"),
      _c(
        "v-content",
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      ),
      _c(
        "v-footer",
        { staticClass: "white--text", attrs: { app: "", color: "#074975" } },
        [
          _c("span", [_vm._v("Aileron")]),
          _c("v-spacer"),
          _c("span", [_vm._v("© " + _vm._s(new Date().getFullYear()) + " ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }