var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list",
    [
      _c(
        "v-list-item",
        { staticClass: "result-item" },
        [
          _c(
            "v-icon",
            { staticClass: "mr-3", attrs: { color: "primary", large: "" } },
            [_vm._v(_vm._s(_vm._f("documentIconFilter")(_vm.result)))]
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                [
                  _c(
                    "a",
                    {
                      staticClass: "result-title",
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(_vm.result)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.result.title))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text--secondary font-italic ml-2 mr-3 subtitle-2",
                    },
                    [_vm._v(_vm._s(_vm.result.filename || _vm.result.fileName))]
                  ),
                  _vm._l(
                    [..._vm.result.disciplines, ..._vm.result.tags],
                    function (discipline, i) {
                      return _c(
                        "v-chip",
                        {
                          key: i,
                          staticClass: "ml-1",
                          attrs: { color: "primary", small: "", ripple: false },
                        },
                        [_vm._v(_vm._s(discipline))]
                      )
                    }
                  ),
                ],
                2
              ),
              _vm.result.textHighlights
                ? _c(
                    "div",
                    _vm._l(_vm.result.textHighlights, function (highlight, i) {
                      return _c("v-list-item-subtitle", {
                        key: i,
                        domProps: { innerHTML: _vm._s("• " + highlight) },
                      })
                    }),
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-list-item-subtitle", {
                        domProps: { innerHTML: _vm._s(_vm.result.description) },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c("v-list-item-action", [
            _c(
              "div",
              [
                _c("span", { staticClass: "resultSize" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatBytes")(_vm.result.fileSizeBytes))
                  ),
                ]),
                _vm.loading
                  ? _c(
                      "v-btn",
                      { staticClass: "pb-1", attrs: { icon: "" } },
                      [
                        _vm.loading
                          ? _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "secondary" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.loading
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "pb-1",
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(_vm.result)
                          },
                        },
                      },
                      [
                        !_vm.loading
                          ? _c("v-icon", { attrs: { color: "secondary" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("documentIconMapping")(_vm.result)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }