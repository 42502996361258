var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c("SideDrawer"),
      _c("Toolbar"),
      _c(
        "v-content",
        [
          _c(
            "v-fade-transition",
            { attrs: { mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { dark: "", app: "", color: "primary" } },
        [
          _c("span", [_vm._v("Aileron")]),
          _c("v-spacer"),
          _c("span", [
            _vm._v(
              "If you need assistance please email customerservice@aileron.org"
            ),
          ]),
          _c("v-spacer"),
          _c("span", [_vm._v("© " + _vm._s(new Date().getFullYear()) + " ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }